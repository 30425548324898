.block.banner {
	padding: 30px 0 0;
	text-align: center;
	border-bottom: 3px solid $white;
	background-image: linear-gradient(
		to top,
		theme(highlight, dark) 17px,
		theme(highlight, base) 17px,
		theme(highlight, base) 35px,
		#c0c0c0 35px,
		#f5f5f5
	);


	@include media-breakpoint-up(md) {
		padding-top: 45px;
		background-image: linear-gradient(
			to top,
			theme(highlight, dark) 20px,
			theme(highlight, base) 20px,
			theme(highlight, base) 40px,
			#c0c0c0 40px,
			#f5f5f5
		);		
		.logo small { padding: 10px; }
	}

}