.branding-logo {
	margin: 0;
}

	.logo {
		display: inline-block;
		@include font-size(28);
		line-height: 1em;
		text-align: center;
		text-decoration: none;

		&:hover {
			strong {
				background-position: top right;
			}
		}

		strong {
			font-weight: $heading-weight-bolder;
			background: linear-gradient( to right, theme(primary, base), theme(secondary, base) );
			background-size: 200%;
			background-position: top left;
	    	background: -webkit-gradient( linear, left top, right top, from(theme(primary, base)), to(theme(secondary, base)) );
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			-webkit-box-decoration-break: clone;
			box-decoration-break: clone;
		}
		
		small {
			display: block;
			padding: 8px 10px;
			@include font-size(19);
			font-weight: $heading-weight-bold;
			line-height: 1em;
			letter-spacing: 7px;
			color: $white;
		}

	}