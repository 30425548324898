.widget {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #343a40; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

body {
  min-width: 360px;
  background-color: #f5f5f5;
  height: 100%;
  min-height: 100%;
  padding-bottom: 71px; }
  body .site_info {
    position: absolute;
    bottom: 0;
    width: 100%; }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 992px) {
  .front_jumbo .row {
    align-items: center; }
  .front_jumbo .jumbo_marketing {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .front_jumbo .jumbo_conversion {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .content.flip .main {
    order: 2; }
  .content.flip .sidebar {
    order: 1; } }

html {
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.725em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 18px;
      font-size: 1.125rem; } }

body {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 10px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.0425em; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-weight: 400; }

h4, .h4 {
  font-size: 1.05rem;
  font-weight: 600; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 1.05rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 1.15rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 1.1rem;
  font-weight: 900; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.1rem; } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h2, .h2 {
  font-size: 1.3rem;
  font-weight: 600; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 1.3rem; } }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.8rem; } }

h1, .h1 {
  font-size: 1.8rem;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 1.8em; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.2rem; } }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 115%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #343a40; }
  blockquote.bq--primary {
    border-color: #428bca; }
  blockquote.bq--secondary {
    border-color: #6f42c1; }
  blockquote.bq--highlight {
    border-color: #333; }
  blockquote.bq--accent {
    border-color: #7cc3f7; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 900;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #343a40; }
  .bq--primary .bq--title {
    color: #428bca; }
  .bq--secondary .bq--title {
    color: #6f42c1; }
  .bq--highlight .bq--title {
    color: #333; }
  .bq--accent .bq--title {
    color: #7cc3f7; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #343a40;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #428bca; }
  blockquote.bq-alt.bq--secondary {
    border-color: #6f42c1; }
  blockquote.bq-alt.bq--highlight {
    border-color: #333; }
  blockquote.bq-alt.bq--accent {
    border-color: #7cc3f7; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #333; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #428bca; }
  a:hover, a:focus {
    color: #5697d0;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #b8a2e0; }

a.img-link {
  text-decoration: none; }

.phone {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  cursor: default;
  color: #6f42c1; }
  .phone:hover, .phone:active, .phone:focus {
    text-decoration: none;
    color: #6f42c1;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #343a40; }
  .phone.mask:hover {
    outline: none;
    color: #343a40; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .list_icon, .list-hours, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list_icon li {
  padding-left: 30px;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.png"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.png"); }

.icon_check-highlight li {
  background-image: url("/_/images/icons/cm_highlight.png"); }

.icon_check-accent li {
  background-image: url("/_/images/icons/cm_accent.png"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.png"); }

.icon_check-review li {
  background-image: url("/_/images/icons/cm_review.png"); }

.icon_check-white li {
  background-image: url("/_/images/icons/cm_white.png"); }

.list-hours li {
  margin-bottom: 0 !important; }

[class*="list-block"] {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  [class*="list-block"] li:not(:last-child) {
    margin-bottom: 0.25em; }
  [class*="list-block"] li {
    display: inline-block; }
  [class*="list-block"] li {
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -o-transform: skew(-15deg);
    transform: skew(-15deg);
    margin-bottom: 0.5em !important;
    padding: 3px 10px;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #343a40;
    color: #fff; }
  [class*="list-block"] li:not(:last-child) {
    margin-right: 0.25em; }

.list-block--primary li {
  background-color: #548ab8; }

.list-block--secondary li {
  background-color: #6f42c1; }

.list-block--highlight li {
  background-color: #333; }

.list-block--accent li {
  background-color: #7cc3f7; }

.list-block--success li {
  background-color: #28a745; }

.list-block--danger li {
  background-color: #dc3545; }

.list-block--warning li {
  background-color: #ffc107; }

.list-block--info li {
  background-color: #0182C8; }

@media (min-width: 576px) {
  .list-double-sm-up {
    display: flex;
    flex-wrap: wrap; }
    .list-double-sm-up > li {
      width: 50%; } }

@media (min-width: 768px) {
  .list-triple-md-up {
    display: flex;
    flex-wrap: wrap; }
    .list-triple-md-up > li {
      width: 33.33333%; } }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    width: 100%;
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 0.92rem;
    font-style: italic;
    line-height: 1.325em;
    color: #4b545c; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt15 {
  margin-top: 15px !important; }

.mt1 {
  margin-top: 1em !important; }

.mt2 {
  margin-top: 1em !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb1 {
  margin-bottom: 1em !important; }

.mb2 {
  margin-bottom: 2em !important; }

.notrfm {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 900 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 600 !important; }

.hw2 {
  font-weight: 900 !important; }

.color--text {
  color: #343a40 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #428bca !important; }

.color--secondary {
  color: #6f42c1 !important; }

.color--highlight {
  color: #333 !important; }

.color--link {
  color: #428bca !important; }

.color--review {
  color: #fd7e14 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #343a40 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #428bca !important; }

.bg--secondary {
  background-color: #6f42c1 !important; }

.bg--highlight {
  background-color: #333 !important; }

.bg--link {
  background-color: #428bca !important; }

.bg--review {
  background-color: #fd7e14 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f5f5f5 !important; }

.gdfancybg--text {
  background: #343a40;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #343a40; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #428bca;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #428bca; }

.gdfancybg--secondary {
  background: #6f42c1;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #6f42c1; }

.gdfancybg--highlight {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #333; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.site_alert > .alert:first-of-type {
  margin-top: 1em; }

.site_alert > .alert:last-of-type {
  margin-bottom: 0; }

.alert {
  border: 1px solid #c4c4c4;
  background-color: white;
  color: #343a40;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert strong {
    font-weight: 800; }

.alert-primary {
  border: 1px solid #3071a9;
  background-color: #e1edf7;
  color: #343a40; }
  .alert-primary strong, .alert-primary a {
    color: #2a6496; }

.alert-secondary {
  border: 1px solid #59339d;
  background-color: #e4dcf3;
  color: #343a40; }
  .alert-secondary strong, .alert-secondary a {
    color: #4e2d89; }

.alert-highlight {
  border: 1px solid #1a1a1a;
  background-color: #999999;
  color: #343a40; }
  .alert-highlight strong, .alert-highlight a {
    color: #0d0d0d; }

.alert-accent {
  border: 1px solid #4cadf4;
  background-color: white;
  color: #343a40; }
  .alert-accent strong, .alert-accent a {
    color: #34a2f3; }

.alert-success {
  border: 1px solid #1e7e34;
  background-color: #afecbd;
  color: #343a40; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #bd2130;
  background-color: #fae3e5;
  color: #343a40; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #016195;
  background-color: #97dafe;
  color: #343a40; }
  .alert-info strong, .alert-info a {
    color: #01517c; }

.alert-warning {
  border: 1px solid #d39e00;
  background-color: #fff4d3;
  color: #343a40; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #3071a9;
  background-color: #e1edf7;
  color: #343a40; }
  .alert-link strong, .alert-link a {
    color: #2a6496; }

.btn {
  border-radius: 4px;
  cursor: pointer; }
  .btn:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.btn-link {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #3071a9;
  background-color: #428bca;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-link:hover {
    background-color: #6aa3d5;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-primary {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #3071a9;
  background-color: #428bca;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-primary:hover {
    background-color: #6aa3d5;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-secondary {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #59339d;
  background-color: #6f42c1;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-secondary:hover {
    background-color: #8c68ce;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-highlight {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #1a1a1a;
  background-color: #333;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-highlight:hover {
    background-color: #4d4d4d;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-accent {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #4cadf4;
  background-color: #7cc3f7;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-accent:hover {
    background-color: #acd9fa;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-success {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #1e7e34;
  background-color: #28a745;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-success:hover {
    background-color: #34ce57;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-danger {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-danger:hover {
    background-color: #e4606d;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-info {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #016195;
  background-color: #0182C8;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-info:hover {
    background-color: #01a3fb;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-review {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #dc6502;
  background-color: #fd7e14;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-review:hover {
    background-color: #fd9a47;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-warning {
  display: inline-block;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #d39e00;
  background-color: #ffc107;
  color: #fff;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-warning:hover {
    background-color: #ffce3a;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn_close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn_close .close_img {
    width: 0.75rem; }
  .btn_close:hover {
    background-color: #921925; }

.btn--sm {
  padding: 5px 15px;
  font-size: 15px;
  font-size: 0.9375rem; }

.btn--lg {
  padding: 16px 20px;
  font-size: 17px;
  font-size: 1.0625rem; }

.btn--wide {
  display: block; }

.copyright_data ul > li {
  line-height: 1.1em; }

.copyright_data a {
  color: #fff;
  font-weight: 600;
  text-decoration: none; }

@media (min-width: 576px) {
  .copyright_data ul > li {
    display: inline-block; }
    .copyright_data ul > li:not(:last-child) {
      margin-right: 0.75em; } }

.coverage {
  padding: 1em;
  border: 1px solid #ddd;
  background-color: #fff; }
  .coverage > *:first-child {
    margin-top: 0; }
  .coverage > *:last-child {
    margin-bottom: 0; }

.coverage_title {
  margin-bottom: 0.5em;
  font-size: 17px;
  font-weight: 800; }

.coverage_city {
  font-size: 14px;
  line-height: 1.25em; }

.form {
  font-size: 15px;
  font-size: 0.9375rem;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form-title {
  margin-top: 0; }

.form_group {
  margin: 0 0 8px;
  padding: 0; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }
  .form_group.form_submit {
    margin-bottom: 0; }

label {
  display: block;
  margin: 0 0 1px 2px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0275em; }

.form-control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-weight: 600;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none; }
  .form-control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form-control {
  min-height: 150px;
  line-height: 1.275em; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 900;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  overflow: hidden;
  height: 65px;
  width: 255px;
  background-color: #dcdcdc; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -6px;
    left: -24px;
    transform: scale(0.85); }

.form_quote {
  padding: 15px;
  border: 3px solid #fff;
  background-color: rgba(255, 255, 255, 0.5); }
  .form_quote .form_submit .btn {
    transform: scale(1); }

.gallery {
  margin-bottom: 1em; }

.gallery_sidebar img {
  display: block;
  width: 100%;
  max-width: 360px;
  margin: 0 auto 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.branding-logo {
  margin: 0; }

.logo {
  display: inline-block;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1em;
  text-align: center;
  text-decoration: none; }
  .logo:hover strong {
    background-position: top right; }
  .logo strong {
    font-weight: 900;
    background: linear-gradient(to right, #428bca, #6f42c1);
    background-size: 200%;
    background-position: top left;
    background: -webkit-gradient(linear, left top, right top, from(#428bca), to(#6f42c1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
  .logo small {
    display: block;
    padding: 8px 10px;
    font-size: 19px;
    font-size: 1.1875rem;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: 7px;
    color: #fff; }

.widget {
  margin: 0 0 15px;
  padding: 15px;
  font-size: 85%;
  border: 1px solid #ddd;
  background-color: #fff; }
  .widget > *:last-child {
    margin-bottom: 0; }
  .widget.widget-default .widget-title {
    color: #343a40; }
  .widget.widget-text {
    border-color: #343a40; }
    .widget.widget-text .widget-title {
      background-color: #343a40; }
  .widget.widget-primary {
    border-color: #428bca; }
    .widget.widget-primary .widget-title {
      background-color: #428bca; }
  .widget.widget-secondary {
    border-color: #6f42c1; }
    .widget.widget-secondary .widget-title {
      background-color: #6f42c1; }
  .widget.widget-highlight {
    border-color: #333; }
    .widget.widget-highlight .widget-title {
      background-color: #333; }
  .widget.widget-link {
    border-color: #428bca; }
    .widget.widget-link .widget-title {
      background-color: #428bca; }
  .widget.widget-review {
    border-color: #fd7e14; }
    .widget.widget-review .widget-title {
      background-color: #fd7e14; }
  .widget.widget-success {
    border-color: #28a745; }
    .widget.widget-success .widget-title {
      background-color: #28a745; }
  .widget.widget-danger {
    border-color: #dc3545; }
    .widget.widget-danger .widget-title {
      background-color: #dc3545; }
  .widget.widget-info {
    border-color: #0182C8; }
    .widget.widget-info .widget-title {
      background-color: #0182C8; }
  .widget.widget-warning {
    border-color: #ffc107; }
    .widget.widget-warning .widget-title {
      background-color: #ffc107; }

.widget-title:not(.plain) {
  margin: -15px -15px 15px;
  padding: 12px 15px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ddd;
  color: #fff; }

.widget-title.plain {
  margin: 0 0 5px; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }
  .widget_plain .widget-title {
    margin: 0 0 15px;
    padding: 0px;
    background-color: transparent;
    color: inherit; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget-rwreviews {
    overflow: hidden; }
    .widget-rwreviews p {
      float: left;
      width: 49%; }
      .widget-rwreviews p:nth-child(odd) {
        clear: both; }
      .widget-rwreviews p:nth-child(even) {
        float: right; } }

.widget-pleads {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-width: 3px;
  border-color: #07b; }
  .widget-pleads .widget-title {
    background-color: #07b; }
  .widget-pleads img {
    max-width: 240px;
    margin: 0 auto 0.5em; }

.block.banner {
  padding: 30px 0 0;
  text-align: center;
  border-bottom: 3px solid #fff;
  background-image: linear-gradient(to top, #1a1a1a 17px, #333 17px, #333 35px, #c0c0c0 35px, #f5f5f5); }
  @media (min-width: 768px) {
    .block.banner {
      padding-top: 45px;
      background-image: linear-gradient(to top, #1a1a1a 20px, #333 20px, #333 40px, #c0c0c0 40px, #f5f5f5); }
      .block.banner .logo small {
        padding: 10px; } }

.front_jumbo {
  padding: 30px 15px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.3) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.3) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), linear-gradient(45deg, #428bca, #6f42c1);
  color: #fff; }
  @media (min-width: 768px) {
    .front_jumbo {
      font-size: 20px;
      font-size: 1.25rem; }
      .front_jumbo .list > li:not(:last-child) {
        margin-bottom: 10px; } }
  @media (min-width: 992px) {
    .front_jumbo .jumbo_marketing {
      padding-right: 30px;
      margin-bottom: 0; }
    .front_jumbo .jumbo_conversion {
      padding-left: 5px; } }

.jumbo_marketing {
  margin-bottom: 20px; }

.marketing-title {
  margin-top: 0;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 900; }

.block.page-title {
  padding: 45px 0;
  color: #fff;
  border-bottom: 5px solid #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.3) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.3) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), linear-gradient(45deg, #428bca, #6f42c1); }
  .block.page-title h1 {
    margin: 0;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1em;
    text-align: center; }
  @media (min-width: 768px) {
    .block.page-title {
      padding: 120px 0 15px; }
      .block.page-title h1 {
        font-size: 24px;
        font-size: 1.5rem; } }
  @media (min-width: 992px) {
    .block.page-title h1 {
      text-align: left; } }
  @media (min-width: 1200px) {
    .block.page-title h1 {
      font-size: 30px;
      font-size: 1.875rem; } }

.block {
  padding: 30px 0; }

.city_menu {
  padding: 15px; }
  .city_menu .city-menu-title {
    margin-top: 0;
    font-size: 18px;
    font-size: 1.125rem; }
  .city_menu ul > li > a[href="#"] {
    border-color: #555 !important;
    background-color: #777 !important;
    transform: scale(0.9); }
    .city_menu ul > li > a[href="#"]:hover {
      transform: scale(0.9);
      cursor: default; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }

.company_block {
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991px) {
    .company_block .company_t {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px; }
      .company_block .company_t .company_logo,
      .company_block .company_t .company_call {
        width: 50%;
        margin: 0; }
    .company_block .list-company li {
      display: inline-block;
      margin-right: 5px; } }
  @media (min-width: 992px) {
    .company_block .gallery img {
      display: block;
      width: 100%; } }

.company_logo {
  display: block;
  width: 100%;
  max-width: 360px;
  margin: 0 auto; }

.company_call {
  line-height: 1em; }
  .company_call .phone {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 900;
    color: #343a40; }
  .company_call .call_emergency {
    display: block;
    margin-top: 5px;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.0325em;
    color: #dc3545; }

.company_address {
  line-height: 1.275em; }

.list-company {
  margin-bottom: 20px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600; }
  .list-company li {
    margin-bottom: 7px; }
  .list-company span {
    display: block;
    padding: 3px 15px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    background: linear-gradient(to right, rgba(66, 139, 202, 0.8), rgba(111, 66, 193, 0.8));
    color: #fff; }

.site_info {
  padding: 20px 0;
  background-color: #1a1a1a;
  color: #fff; }
